import React from 'react';
import styled from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const IntroBlock = styled.div`
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  background: var(--primaryColor);
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: clamp(20px, 3vw, 28px);
    font-weight: 300;
    margin: 0;
  }
  h3 {
    font-size: 18px;
    font-weight: 400;
  }
  .ybm {
    font-size: clamp(30px, 4.5vw, 65px);
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

const TYContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
  --primaryColor: #000000;
  --secondaryColor: #000000;

  .ctaCall {
    text-decoration: none;
    color: inherit;
  }

  font-family: 'Poppins', Arial, san-serif;
  width: 100%;
  margin: auto;
  img {
    margin: 0;
  }
  ul {
    display: block;
    margin: 0;
    padding: 0;
    column-count: 2;
  }
  section {
    box-sizing: border-box;
    width: calc(100vw - 40px);
    max-width: 700px;
    padding: 10px 20px 10px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 3px 8px #555;
    margin-bottom: 10px;
    @media (max-width: 801px) {
      padding: 10px;
    }
  }
  .featuredText {
    text-align: center;
    font-size: clamp(20px, 3vw, 28px);
    font-weight: 700;
    text-transform: uppercase;
    margin: 20px 0;
  }
  .additionalHeadline {
    text-align: left;
    font-size: clamp(15px, 3vw, 26px);
    font-weight: 500;
    margin: 20px auto;
    width: calc(100% - 30px);
    max-width: 1160px;
  }
  .contactInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 980px;
    margin: auto;
    .phoneNumber {
      background: #07698e;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-top: 10px;
      a {
        color: #fff;
      }
      @media (max-width: 801px) {
        width: 100%;
        text-align: center;
      }
    }
    .clickApply {
      height: 49px;
      background: #07698e;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      @media (max-width: 801px) {
        width: 100%;
        text-align: center;
      }
    }
    @media (max-width: 801px) {
      flex-wrap: wrap;
    }
  }
  .logoContainer {
    width: calc(100% - 20px);
    max-width: 400px;
    margin: 20px auto 60px;
    img {
      width: 100%;
    }
  }

  .questions {
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    background: #e0e1e3;
    border: none;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    color: #333;
    padding-bottom: 20px;
    .questionsContainer {
      max-width: 1170px;
      margin: auto;
    }
    .questionsHeadline {
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      color: #00a6ce;
    }
    .threeItemBlock {
      margin-top: 20px;
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 35px;
      .contentBlock {
        width: 31%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        margin-bottom: 20px;
        p {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.1;
          width: 100%;
          margin-left: 15px;
          margin-bottom: 0;
        }
        .iconContainer {
          width: 85px;
          svg {
            width: 75px;
          }
        }
        @media (max-width: 801px) {
          width: 100%;
        }
      }
      @media (max-width: 801px) {
        flex-wrap: wrap;
      }
    }
    .ctcLink {
      text-decoration: none;
    }
    .dividerContainer {
      box-sizing: border-box;
      width: calc(100% - 20px);
      max-width: 700px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--secondaryColor);
      color: #fff;
      padding: 10px 8px;
      border-radius: 50px;
      margin: -20px auto 30px;
      box-shadow: 0 5px 12px #555;

      h2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin: 0;
        font-size: clamp(24px, 3.75vw, 34px);
        font-weight: 400;
        text-align: center;
        .phone {
          font-size: 0.8em;
          font-weight: 400;
        }
      }
    }
    .questionList {
      max-width: 1160px;
      margin: 20px auto 0;
      ul {
        display: block;
        column-count: 2;
        column-gap: 20px;
        font-size: 16px;
        font-weight: 400;
        padding-left: 5px;
        @media (max-width: 801px) {
          column-count: 1;
        }
        li {
          list-style-type: none;
          margin-bottom: 10px;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 16px;
          line-height: 1.25;
          .bullet {
            width: 12px;
            margin-right: 5px;
            svg {
              width: 100%;
            }
          }
          p {
            max-width: calc(100% - 20px);
          }
        }
      }
    }
  }
  .bottomContainer {
    background-color: #ffffff;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 80px;

    p {
      font-size: 13px;
      text-align: center;
    }
    .bottomLogoContainer {
      width: 100%;
      justify-content: right;
      display: flex;
      flex-direction: row;
      justify-content: end;
    }
    @media (max-width: 801px) {
      margin: 20px;
    }
  }
  .bbbLogos {
    height: 65px;
    width: auto;
    margin: 15px;
  }
  .equalLogos {
    height: 65px;
    width: auto;
    margin: 15px;
  }
  .mrmlaLogos {
    height: 55px;
    width: auto;
    margin: 15px;
  }
  .firstStatement {
    font-weight: bold;
  }
  .ehoContainer {
    max-width: 60px;
    padding-right: 10px;
  }

  .circle {
    fill: var(--primaryColor);
    &.bulletPoint {
      fill: #263b79;
    }
  }
`;

const ElbfThankyou = () => {
  return (
    <>
      <TYContainer>
        <IntroBlock>
          <h1>
            <span className="ybm">You've been matched!</span>
          </h1>
        </IntroBlock>
        <p className="featuredText">With our featured provider:</p>
        <div className="logoContainer">
          <img
            src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/elbfthankyou.jpg"
            alt="Prime Choice"
          />
        </div>
        <section className="questions">
          <div className="questionsContainer">
            <div className="threeItemBlock">
              <div className="contentBlock">
                <div className="iconContainer">
                  <svg
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="50" cy="50" r="50" className="circle" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M49.993 21.6651L27.3049 41.6472H72.6811L49.993 21.6651ZM52.6367 15.9982C51.1255 14.6673 48.8605 14.6673 47.3492 15.9982L19.3643 40.6454C16.5973 43.0824 18.321 47.6472 22.008 47.6472H24.5377V75.0004C24.5377 76.6572 25.8808 78.0004 27.5377 78.0004L41.8109 78.0004H47.8109H53.0842H59.0842L72.4483 78.0004C74.1051 78.0004 75.4483 76.6572 75.4483 75.0004V47.6472H77.9779C81.665 47.6472 83.3886 43.0824 80.6217 40.6455L52.6367 15.9982ZM59.0842 72.0004L69.4483 72.0004V47.7578H30.5377V72.0004L41.8109 72.0004V63.0793C41.8109 58.3094 45.6777 54.4427 50.4476 54.4427C55.2174 54.4427 59.0842 58.3094 59.0842 63.0793V72.0004ZM47.8109 72.0004H53.0842V63.0793C53.0842 61.6231 51.9037 60.4427 50.4476 60.4427C48.9914 60.4427 47.8109 61.6231 47.8109 63.0793V72.0004Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <p>
                  For some companies, reverse mortgages are just one of many
                  offerings—but at Longbridge, they’re all we do. Our
                  knowledgeable staff has years of experience and are true
                  specialists in the industry.
                </p>
              </div>
              <div className="contentBlock">
                <div className="iconContainer">
                  <svg
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="50" cy="50" r="50" className="circle" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M23 31.0224L50.6498 25.0734L76.1853 30.9517V31.9058H23V31.0224ZM50.0408 19.0671C50.4709 18.9746 50.9161 18.9778 51.3448 19.0765L79.8583 25.6403C81.2202 25.9539 82.1853 27.1663 82.1853 28.5639V34.9058C82.1853 36.5626 80.8421 37.9058 79.1853 37.9058H72.4075V39.195V60.7316H78.8148C80.4716 60.7316 81.8148 62.0748 81.8148 63.7316V74C81.8148 74.5523 81.367 75 80.8148 75H18.8148C18.2625 75 17.8148 74.5523 17.8148 74V63.7316C17.8148 62.0748 19.1579 60.7316 20.8148 60.7316H25.9629V39.195V37.9058H20C18.3431 37.9058 17 36.5626 17 34.9058V28.5992C17 27.1855 17.9869 25.9637 19.369 25.6663L50.0408 19.0671ZM36.2593 60.7316H31.9629V39.195H36.2593V60.7316ZM25.9629 66.7316V66.7675H31.9629H36.2593H42.2593V66.7316H56.1111V66.7675H62.1111H66.4075H72.4075V66.7316H75.8148V69H23.8148V66.7316H25.9629ZM56.1111 60.7316H42.2593V39.195V37.9058H56.1111V39.195V60.7316ZM66.4075 60.7316H62.1111V39.195H66.4075V60.7316Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <p>
                  Customer service is our highest priority. We maintain an A+
                  rating from the BBB and a 4.8/5-star customer satisfaction
                  rating from Trustpilot® – an independent review site
                </p>
              </div>
              <div className="contentBlock">
                <div className="iconContainer">
                  <svg
                    className="icon"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="50" cy="50" r="50" className="circle" />
                    <path
                      d="M56.977 49.7173C56.977 49.7173 47.6903 49.7156 42.6461 49.7173C37.6019 49.719 37.9708 38.1602 42.6461 38.1558C44.3964 38.1542 47.162 38.1538 49.9372 38.1539M46.6688 49.7532C46.6688 49.7532 50.6058 49.7129 56.4742 49.7207C62.3425 49.7285 62.6725 61.3528 56.4742 61.3506C50.2758 61.3485 40.1319 61.3506 40.1319 61.3506M59.2398 38.1558C59.2398 38.1558 54.5749 38.1541 49.9372 38.1539M49.9372 38.1539V31M49.6858 61.3506V69"
                      stroke="white"
                      strokeWidth="6"
                      strokeLinecap="round"
                      strokeLinejoin="bevel"
                    />
                    <rect
                      x="21"
                      y="21"
                      width="58"
                      height="58"
                      rx="5"
                      stroke="white"
                      strokeWidth="6"
                    />
                  </svg>
                </div>
                <p>
                  In addition to our competitive pricing, Longbridge offers low
                  rates and other special pricing programs for those who
                  qualify.
                </p>
              </div>
            </div>
            <div className="questionList">
              <ul>
                <li>
                  <div className="bullet">
                    <svg
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        r="50"
                        className="circle bulletPoint"
                      />
                    </svg>
                  </div>
                  <p>How does my credit score affect my loan?</p>
                </li>
                <li>
                  {' '}
                  <div className="bullet">
                    <svg
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        r="50"
                        className="circle bulletPoint"
                      />
                    </svg>
                  </div>
                  <p>Which program is right for me?</p>
                </li>
                <li>
                  {' '}
                  <div className="bullet">
                    <svg
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        r="50"
                        className="circle bulletPoint"
                      />
                    </svg>
                  </div>
                  <p>Will I always have someone to answer my questions?</p>
                </li>
                <li>
                  <div className="bullet">
                    <svg
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        r="50"
                        className="circle bulletPoint"
                      />
                    </svg>
                  </div>
                  <p>
                    One of our Longbridge Financial specialists will be reaching
                    out to help.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div className="bottomContainer">
          <p className="firstStatement">
            {' '}
            This material has not been reviewed, approved or issued by HUD, FHA
            or any government agency. The company is not affiliated with or
            acting on behalf of or at the direction of HUD/FHA or any other
            government agency.
          </p>
          <p>
            ©2024 Longbridge Financial, LLC NMLS# 957935. 1 International Blvd.,
            Suite 900, Mahwah, New Jersey 07495. 1-855-523-4326. For licensing
            information, go to: www.nmlsconsumeraccess.org. For additional
            Longbridge licensing and disclosures, please visit:
            https://longbridge-financial.com/licensing.
          </p>
          <p>
            Longbridge Financial Company Licensing Information: Arizona Mortgage
            Banker License #0930082; Licensed by the Department of Financial
            Protection and Innovation under the California Residential Mortgage
            Lending Act; Loans made or arranged pursuant to a California
            Financing Law license; Georgia Mortgage Lender Licensee #44082;
            Massachusetts Mortgage Lender License # ML957935; Licensed by the
            New Jersey Department of Banking & Insurance; Licensed Mortgage
            Banker-NYS Department of Financial Services -out-of-state branch
            address 1 International Blvd., Suite 900, Mahwah, New Jersey 07495;
            Rhode Island Licensed Lender. For California consumers: For
            information about our privacy practices, please visit
            https://www.longbridge-financial.com/privacy.
          </p>
          <p>
            Charges such as an origination fee, mortgage insurance premiums,
            closing costs and/or servicing fees, if applicable, may be assessed
            and will be added to the loan balance. As long as you comply with
            the terms of the loan, you retain title until you sell or transfer
            the property, and, therefore, you are responsible for paying
            property taxes, insurance and maintenance. Failing to pay these
            amounts may cause the loan to become immediately due and/or subject
            the property to a tax lien, other encumbrance or foreclosure. The
            loan balance grows over time, and interest is added to that balance.
            Interest on a reverse mortgage is not deductible from your income
            tax until you repay all or part of the interest on the loan.
            Although the loan is non-recourse, at the maturity of the loan, the
            lender will have a claim against your property and you or your heirs
            may need to sell the property in order to repay the loan, or use
            other assets to repay the loan in order to retain the property.
          </p>
          <p>
            Not all products and options are available in all states. Terms
            subject to change without notice. Certain conditions and fees apply.
            This is not a loan commitment. All loans subject to approval.
          </p>

          <div className="bottomLogoContainer">
            <img
              src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/BBB_logo.png"
              alt="BBB Logo"
              className="bbbLogos"
            />
            <img
              src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/Equalhousinglender.png"
              alt="Equal Housing Logo"
              className="equalLogos"
            />
            <img
              src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/NRMLA logo.png"
              alt="NRMLA Logo"
              className="mrmlaLogos"
            />
          </div>
        </div>{' '}
      </TYContainer>
    </>
  );
};

export default withThankyouWrapper(ElbfThankyou);
